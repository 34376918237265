import logo from './logo.svg';
import './App.css';
import b from './b.png'
import BG from './BG.png'
import a from './a.png'
import Layer from './Layer.png'
import A1 from './A1.png'
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import jsonp from 'jsonp';
import { toast } from 'wc-toast';

function Web1() {

  const navigate = useNavigate()
  const [pass, setPass] = useState('')

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const windowSizeHandler = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    };
    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  function submit() {
    const url = 'https://deliveryboxx.us22.list-manage.com/subscribe/post-json?u=d074fe6f138b845aef3c10703&amp;id=1bab501883&amp;f_id=00eec8e1f0';
    jsonp(`${url}&EMAIL=${pass}`, { param: 'c' }, (_, data) => {
      const { msg, result } = data
      console.log('Msg ', msg, result)
      if (result == 'success') {
        toast('Thank you for subscribing!')
      }
      else toast(msg)
    });
  }

  function ismobile() {
    return width <= 700
  }

  const mobile1 = <div style={{ display: 'flex', flexDirection: 'column', width: width, height: height, }}>

    <img src={BG} style={{ width: 384, height: height, alignSelf: 'center', position: 'absolute', top: 0 }} />


    <div style={{ display: 'flex', width: 384, height: 33, flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'center', position: 'absolute', top: 50 }}>
      <img src={Layer} style={{ width: 164, height: 30, alignSelf: 'center', marginRight: 40 }} />


      <div onClick={() => navigate('/Web2')} style={{ display: 'flex', width: 104, height: 40, borderRadius: 4, backgroundColor: '#D93856', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginLeft: 40 }}>
        <text style={{ fontSize: 15, fontWeight: 600, alignSelf: 'center', color: 'white' }}>
          Early Access
        </text>
      </div>
    </div>



    <img src={A1} style={{ width: 300, height: 29, marginTop: 120, alignSelf: 'center', marginLeft: 0, position: 'absolute', top: 20 }} />

    <div style={{ width: 350, alignSelf: 'center', display: 'flex', flexDirection: 'column', position: 'absolute', top: 185 }}>
      <text style={{ fontSize: 10, fontWeight: 600, alignSelf: 'center', color: '#B2BEB5', marginTop: 5 }}>
        DeliveryBoxx’s team are excited to announce they will be launching
      </text>
      <text style={{ fontSize: 10, fontWeight: 600, alignSelf: 'center', color: '#B2BEB5' }}>
        their new product very soon. Signup to find out more.
      </text>
    </div>


    <div style={{ display: 'flex', width: width, height: 200, flexDirection: 'column', alignItems: 'center', alignSelf: 'center', justifyContent: 'center', marginTop: 20, position: 'absolute', top: 185 }}>
      <div style={{ display: 'flex', width: 295, height: 54, borderRadius: 6, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start' }}>
        <input placeholder='Email' value={pass} onChange={(e) => setPass(e.currentTarget.value)} style={{ marginLeft: 10, outline: 'none', display: 'flex', width: 265, height: 50, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', borderStyle: 'none' }} />

      </div>
      <div onClick={() => { submit() }} style={{ display: 'flex', width: 130, height: 51, borderRadius: 6, backgroundColor: '#D93856', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 25 }}>
        <text style={{ fontSize: 15, fontWeight: 600, alignSelf: 'center', color: 'white' }}>
          Sign Up
        </text>
      </div>
    </div>

    <wc-toast></wc-toast>

  </div>
  // navigate('/Orders')
  return (ismobile() ? mobile1 :
    <div style={{ display: 'flex', flexDirection: 'column', width: width, height: height, }}>

      <img src={BG} style={{ width: width, height: height, alignSelf: 'center', position: 'absolute', top: 0 }} />


      <div style={{ display: 'flex', width: width, height: 58, flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'space-between', position: 'absolute', top: 50 }}>
        <img src={Layer} style={{ width: 294, height: 51, alignSelf: 'center', marginLeft: 20 }} />


        <div onClick={() => navigate('/Web2')} style={{ cursor: 'pointer', display: 'flex', width: 194, height: 58, borderRadius: 8, backgroundColor: '#D93856', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginRight: 30 }}>
          <text style={{ fontSize: 18, fontWeight: 600, alignSelf: 'center', color: 'white' }}>
            Early Access
          </text>
        </div>
      </div>

      <img src={a} style={{ width: 1073, height: 125, marginTop: 220, alignSelf: 'center', marginLeft: 20, position: 'absolute', top: 20 }} />
      <div style={{ display: 'flex', width: width, height: 57, flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'center', marginTop: 45, marginLeft: 20, position: 'absolute', top: 350 }}>
        <div style={{ display: 'flex', width: 424, height: 57, borderRadius: 8, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start' }}>
          <div style={{ display: 'flex', flexDirection: 'row', width: 410, height: 57, borderRadius: 8, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
            <input placeholder='Email' value={pass} onChange={(e) => setPass(e.currentTarget.value)} style={{ marginLeft: 15, outline: 'none', display: 'flex', width: 340, height: 50, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', borderStyle: 'none' }} />
          </div>
        </div>
        <div onClick={() => { submit() }} style={{ cursor: 'pointer', display: 'flex', width: 153, height: 57, borderRadius: 8, backgroundColor: '#D93856', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginLeft: 10 }}>
          <text style={{ fontSize: 18, fontWeight: 600, alignSelf: 'center', color: 'white' }}>
            Sign Up
          </text>
        </div>
      </div>

      <wc-toast></wc-toast>

    </div>
  );
}

export default Web1;
//<onclick style={{fontSize:18,fontWeight:600,alignSelf:'center',color:'white'}}>
//Sign Up
//</onclick>