import logo from './logo.svg';
import './App.css';

import {
  BrowserRouter,
  Routes, //replaces "Switch" used till v5
  Route,
  HashRouter
} from "react-router-dom";
import Web1 from './Web1';
import Web2 from './Web2';
import Web3 from './Web3';

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Routes>

          <Route path="/" element={<Web1 />} />
          <Route path="/Web2" element={<Web2 />} />
          <Route path="/Web3" element={<Web3 />} />

        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
