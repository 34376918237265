import logo from './logo.svg';
import './App.css';
import b from './b.png'
import BG from './BG.png'
import a from './a.png'
import Layer from './Layer.png'
import lock from './lock.png'
import { useEffect, useState } from 'react';
import { CiUnlock } from "react-icons/ci";
import { useNavigate } from 'react-router-dom';
import { toast } from 'wc-toast';
import A2 from './A2.png'


function Web2() {

      const navigate = useNavigate()

      const [pass, setPass] = useState('')
      const [show, setShow] = useState(true)

      const [width, setWidth] = useState(window.innerWidth);
      const [height, setHeight] = useState(window.innerHeight);

      useEffect(() => {
            const windowSizeHandler = () => {
                  setWidth(window.innerWidth)
                  setHeight(window.innerHeight)
            };
            window.addEventListener("resize", windowSizeHandler);

            return () => {
                  window.removeEventListener("resize", windowSizeHandler);
            };
      }, []);

      function ismobile() {
            return width <= 700
      }

      const mobile2 = <div style={{ display: 'flex', flexDirection: 'column', width: width, height: height, }}>

            <img src={BG} style={{ width: 384, height: height, alignSelf: 'center', position: 'absolute', top: 0 }} />


            <div style={{ display: 'flex', width: 340, height: 30, flexDirection: 'row', alignItems: 'center', alignSelf: 'center', justifyContent: 'flex-start', position: 'absolute', top: 50 }}>
                  <img src={Layer} style={{ width: 164, height: 30, alignSelf: 'center' }} />



            </div>



            <img src={A2} style={{ width: 300, height: 25, marginTop: 120, alignSelf: 'center', marginLeft: 0, position: 'absolute', top: 20 }} />
            <div style={{ width: 350, alignSelf: 'center', display: 'flex', flexDirection: 'column', position: 'absolute', top: 175 }}>

                  <text style={{ fontSize: 9, fontWeight: 600, alignSelf: 'center', color: '#B2BEB5', marginTop: 5 }}>
                        If you do not have access, do not worry, our website will be live
                  </text>
                  <text style={{ fontSize: 9, fontWeight: 600, alignSelf: 'center', color: '#B2BEB5' }}>
                        very soon for all to see! Signup to find out more.
                  </text>
            </div>


            <div style={{ display: 'flex', width: 309, height: 25, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', position: 'absolute', top: 195,marginTop:20 }}>
                  <text style={{ fontSize: 15, fontWeight: 600, alignSelf: 'center', color: 'white' }}>
                        Password
                  </text>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', width: 310, height: 51, borderRadius: 6, alignSelf: 'center', alignItems: 'center', justifyContent: 'center' , position: 'absolute', top: 245 }}>
                  <div style={{ display: 'flex', width: 309, height: 51, borderRadius: 6, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: 300, height: 57, borderRadius: 8, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                              <input type={show ? 'password' : 'text'} placeholder='Password' value={pass} onChange={(e) => setPass(e.currentTarget.value)} style={{ outline: 'none', display: 'flex', width: 250, height: 50, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', borderStyle: 'none', marginLeft: 6 }} />
                              <div onClick={() => setShow(!show)} style={{ display: 'flex', width: 40, height: 57, borderRadius: 8, alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginRight: 10 }}>
                                    {show ? <img src={lock} style={{ width: 18, height: 18, alignSelf: 'center' }} /> : <CiUnlock size={18} color='black' />}
                              </div>
                        </div>
                  </div>

            </div>

            <div onClick={() => { console.log(pass); if (pass == '5921') navigate('/Web3', { state: { pass: 5921 } }); else { toast('Password is wrong !') } }} style={{ display: 'flex', width: 130, height: 51, borderRadius: 6, backgroundColor: '#D93856', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 25  , position: 'absolute', top: 285}}>
                  <text style={{ fontSize: 15, fontWeight: 600, alignSelf: 'center', color: 'white' }}>
                        Login
                  </text>
            </div>

            <wc-toast></wc-toast>

      </div>

      return (ismobile() ? mobile2 :
            <div style={{ display: 'flex', flexDirection: 'column', width: width, height: height, }}>

                  <img src={BG} style={{ width: width, height: height, alignSelf: 'center', position: 'absolute', top: 0 }} />

                  <div style={{ width: width, height: 58, display: 'flex', alignSelf: 'center', justifyContent: 'space-between', position: 'absolute', top: 50 }}>
                        <img src={Layer} style={{ width: 294, height: 51, marginLeft: 20 }} />
                        <div style={{ height: 58, width: 60 }}></div>
                  </div>



                  <img src={b} style={{ width: 921, height: 134, marginTop: 130, alignSelf: 'center', position: 'absolute', top: 20 }} />


                  <div style={{ display: 'flex', width: 424, height: 25, alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', position: 'absolute', top: 320 }}>
                        <text style={{ fontSize: 18, fontWeight: 600, alignSelf: 'center', color: 'white' }}>
                              Password
                        </text>
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'row', width: 424, height: 57, borderRadius: 8, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 350 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: 380, height: 57, borderRadius: 8, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'space-between' }}>
                              <input type={show ? 'password' : 'text'} placeholder='Password' value={pass} onChange={(e) => setPass(e.currentTarget.value)} style={{ outline: 'none', display: 'flex', width: 340, height: 50, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'flex-start', borderStyle: 'none' }} />
                              <div onClick={() => setShow(!show)} style={{ display: 'flex', width: 44, height: 57, borderRadius: 8, backgroundColor: '#E3E5E5', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                    {show ? <img src={lock} style={{ width: 18, height: 18, alignSelf: 'center' }} /> : <CiUnlock size={18} color='black' />}
                              </div>
                        </div>
                  </div>


                  <div onClick={() => { if (pass == '5921') navigate('/Web3', { state: { pass: 5921 } }); else { toast('Password is wrong !') } }} style={{ cursor: 'pointer', display: 'flex', width: 153, height: 57, borderRadius: 8, backgroundColor: '#D93856', alignSelf: 'center', alignItems: 'center', justifyContent: 'center', marginLeft: 10, position: 'absolute', top: 440 }}>
                        <text style={{ fontSize: 18, fontWeight: 600, alignSelf: 'center', color: 'white' }}>
                              Login
                        </text>
                  </div>

                  <wc-toast></wc-toast>

            </div>
      );
}

export default Web2;
//<onclick style={{fontSize:18,fontWeight:600,alignSelf:'center',color:'white'}}>
//Sign Up
//</onclick>