import logo from './logo.svg';
import './App.css';
import b from './b.png'
import BG from './BG.png'
import a from './a.png'
import Layer from './Layer.png'
import lock from './lock.png'
import tv from './tv.png'
import React, { useEffect, useState } from 'react'
import G from './G.png'
import video1 from './video1.mov'
import { useLocation, useNavigate } from 'react-router-dom';

//  <img src={Layer} style={{ width: 294, height: 51 }} />

function Web3() {

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const state = useLocation()
  const navigate = useNavigate()

  console.log('State ', state.state)

  useEffect(() => {
    if (state == null||state==undefined||state.state==null) navigate('/')
    else if (state.state.pass == undefined) navigate('/')
  }, [])

  useEffect(() => {
    const windowSizeHandler = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    };
    window.addEventListener("resize", windowSizeHandler);

    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  function ismobile() {
    return width <= 700
  }

  console.log('Width ', width)

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>


      <img src={BG} style={{ width: width, height: height, alignSelf: 'center' }} />


      {!ismobile() ? <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center',position:'absolute',top:100 }}>
        <video width="1000" height="500" controls >
          <source src={video1} type="video/mp4" />
        </video>
      </div> : <div style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center', alignItems: 'center',position:'absolute',top:50 }}>
        <video width="350" height="500" controls >
          <source src={video1} type="video/mp4" />
        </video>
      </div>}


    </div>

  );
}

export default Web3;
//<onclick style={{fontSize:18,fontWeight:600,alignSelf:'center',color:'white'}}>
//Sign Up
//</onclick>